import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { TranslateService } from '@ngx-translate/core';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'snooker-vlaanderen';
  menuItems: MenuItem[] = [];
  home: MenuItem = {};

  constructor(
    private router: Router,
    private authService: AuthService,
    private ccService: NgcCookieConsentService,
    private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.authService.error$.subscribe((error: Error) => {
      if (error.message.includes('blocked')) {
        this.router.navigate(['/unauthorized'], { state: { forceLogout: true }, queryParams: { error: 'app.messages.user.blocked' } });
      }
    });

    this.translate//
      .get(['cookie.message', 'cookie.allow', 'cookie.deny', 'cookie.link', 'cookie.policy'])
      .subscribe(data => {

        this.ccService.getConfig().content = this.ccService.getConfig().content || {};
        // Override default messages with the translated ones
        this.ccService.getConfig().content!.message = data['cookie.message'];
        this.ccService.getConfig().content!.allow = data['cookie.allow'];
        this.ccService.getConfig().content!.deny = data['cookie.deny'];
        this.ccService.getConfig().content!.link = data['cookie.link'];
        this.ccService.getConfig().content!.policy = data['cookie.policy'];

        this.ccService.destroy(); // remove previous cookie bar (with default messages)
        this.ccService.init(this.ccService.getConfig()); // update config with translated messages
      });
  }
}
