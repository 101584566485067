import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  private cacheBuster = new BehaviorSubject<number>(Date.now());
  private refreshSubject = new BehaviorSubject<number>(0);
  public refresh$ = this.refreshSubject.asObservable();

  refreshCache() {
    this.cacheBuster.next(Date.now());
    this.refreshSubject.next(Date.now());
  }

  getSecureUrl(url: string, bypassCache = false): string {
    if (!url) return '';

    if (!bypassCache) return url;

    const separator = url.includes('?') ? '&' : '?';
    return `${url}${separator}t=${this.cacheBuster.value}`;
  }

  getPlaceHolderImage(text: string = '', size: string = '200x200'): string {
    if (text === '') {
      return `https://placehold.co/${size}`;
    } else {
      return `https://placehold.co/${size}?text=${text}`;
    }
  }
}
