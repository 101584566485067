import { inject } from "@angular/core";
import { ResolveFn, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { ApplicationActionsService } from "@app/features/admin/applicationactions/services/applicationactions.service";
import { AuthRepository } from "@app/store/auth/auth.repository";
import { lastValueFrom } from "rxjs";
import { AuthModel } from "../directives/auth.directive";

export const permissionAndAuthResolver: ResolveFn<any> = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const applicationActionService = inject(ApplicationActionsService);
  const authRepository = inject(AuthRepository);
  const router = inject(Router);

  if (route.data && route.data['permission']) {
    let permission = (route.data as any).permission as AuthModel;
    await lastValueFrom(applicationActionService.getApplicationActionsForUser());

    let isAuthorized = authRepository.isAuthorized(permission);

    if (!isAuthorized) {
      router.navigate(['/unauthorized']);
    }
  }
}
