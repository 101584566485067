<footer class="footer text-white mt-4 py-3">
  <div class="container">
    <div class="pt-4">
      <!-- Main Footer Content -->
      <div class="row gx-5 text-center text-md-start">
        <div class="col-12 col-md-4 mb-4">
          <h5>{{ 'app.ui.footer.general' | translate }}</h5>
          <ul class="nav flex-column align-items-center align-items-md-start">
            <li class="nav-item mb-2">
              <a href="#" class="nav-link p-0 text-white">
                {{ 'app.ui.footer.general' | translate }}
              </a>
            </li>
            <li class="nav-item mb-2">
              <a href="#" class="nav-link p-0 text-white">
                {{ 'app.ui.footer.links' | translate }}
              </a>
            </li>
            <li class="nav-item mb-2">
              <a href="#" class="nav-link p-0 text-white">
                {{ 'app.ui.footer.contact' | translate }}
              </a>
            </li>
            <li class="nav-item mb-2">
              <app-language-selector [cookie]="true"></app-language-selector>
            </li>
          </ul>
        </div>

        <div class="col-12 col-md-4 mb-4">
          <h5>{{ 'app.ui.footer.members' | translate }}</h5>
          <ul class="nav flex-column align-items-center align-items-md-start">
            <li class="nav-item mb-2">
              <a routerLink="/members" class="nav-link p-0 text-white">
                {{ 'app.ui.footer.members' | translate }}
              </a>
            </li>
            <li class="nav-item mb-2">
              <a routerLink="/interclub" class="nav-link p-0 text-white">
                {{ 'app.ui.footer.interclub' | translate }}
              </a>
            </li>
            <li class="nav-item mb-2">
              <a href="https://www.snookerbelgium.be" target="_blank" class="nav-link p-0 text-white">
                {{ 'app.ui.footer.tournaments' | translate }}
              </a>
            </li>
            <li class="nav-item mb-2">
              <a href="#" class="nav-link p-0 text-white">
                {{ 'app.ui.footer.forms' | translate }}
              </a>
            </li>
            <li class="nav-item mb-2">
              <a href="#" class="nav-link p-0 text-white">
                {{ 'app.ui.footer.regulations' | translate }}
              </a>
            </li>
          </ul>
        </div>

        <div class="col-12 col-md-4 mb-4">
          <h5>{{ 'app.ui.footer.contact-us' | translate }}</h5>
          <p><small>
              Snooker Vlaanderen VZW<br>
              Zuiderlaan 13<br>
              9000 Gent (België)
            </small></p>
        </div>
      </div>

      <!-- Bottom Footer -->
      <div class="row border-top pt-4">
        <div class="col-12">
          <div class="row align-items-center text-center text-md-start">
            <div class="col-12 col-md-4 mb-3 mb-md-0">
              <p class="mb-0">{{ 'app.ui.footer.copyright' | translate }} {{ currentYear }}</p>
            </div>
            <div class="col-12 col-md-4 mb-3 mb-md-0">
              <p class="mb-0">{{ 'app.ui.footer.environment-version' | translate: { environment: environment, version: appVersion } }}</p>
            </div>
            <div class="col-12 col-md-4">
              <p class="mb-0">
                <small>
                  {{ 'app.ui.footer.developed-by' | translate }}&nbsp;
                  <a rel="noopener" href="https://resonate.be" target="_blank">
                    <img src="assets/images/resonate_logo.png" height="20" alt="resonate_logo">
                  </a>
                </small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
