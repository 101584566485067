<nav class="navbar navbar-expand-md site-nav" [ngClass]="{'fixed-top': pinTopNav}">
  <div class="container-fluid">
    <!-- Logo -->
    <div class="nav-section-left">
      <a class="navbar-brand" routerLink="">
        <img class="logo-shadow" src="assets/images/logosite.png" alt="Logo" height="60"
          class="d-inline-block align-text-top">
      </a>
    </div>

    <!-- Main Navigation Section -->
    <div class="nav-section-center">
      <!-- Navigation Content -->
      <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
        <div class="offcanvas-body">
          <!-- Close Button -->
          <a class="offcanvas-close" data-bs-dismiss="offcanvas">
            <fa-icon [icon]="faXMark"></fa-icon>
          </a>

          <!-- Main Menu -->
          <div class="menu-title py-1 px-2">{{'app.ui.nav.menu' | translate }}</div>
          <ul class="navbar-nav me-auto mb-2 mb-md-0">
            <li class="nav-item px-2 py-1">
              <a class="nav-link" routerLink="news" data-bs-dismiss="offcanvas" [routerLinkActive]="['active']">
                {{ 'app.ui.nav.news' | translate }}
              </a>
            </li>
            <li class="nav-item px-2 py-1">
              <a class="nav-link" routerLink="members" data-bs-dismiss="offcanvas" [routerLinkActive]="['active']">
                {{ 'app.ui.nav.members' | translate }}
              </a>
            </li>
            <li class="nav-item px-2 py-1">
              <a class="nav-link" routerLink="interclub" data-bs-dismiss="offcanvas" [routerLinkActive]="['active']">
                {{ 'app.ui.nav.interclub' | translate }}
              </a>
            </li>
            <li class="nav-item px-2 py-1">
              <a class="nav-link" href="https://www.snookerbelgium.be" target="_blank">
                {{ 'app.ui.nav.tournaments' | translate }}
              </a>
            </li>
          </ul>

          <!-- Mobile Districts Section -->
          <div class="districts-nav-mobile d-md-none">
            <div class="menu-title py-1 px-2">{{'app.ui.nav.districts' | translate }}</div>
            <div class="accordion" id="districtsAccordion">
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#districtsCollapse">
                    {{ selectedGlobalFilter }}
                  </button>
                </h2>
                <div id="districtsCollapse" class="accordion-collapse collapse" data-bs-parent="#districtsAccordion">
                  <div class="accordion-body">
                    <div class="mobile-menu-item" (click)="removeGlobalSiteDistrictFilter()" data-bs-dismiss="offcanvas"
                      [class.active]="isDistrictSelected(null)">
                      {{ "app.ui.nav.all-districts" | translate }}
                    </div>
                    <div class="mobile-menu-item" (click)="setGlobalSiteDistrictFilterToAntwerp()"
                      data-bs-dismiss="offcanvas"
                      [class.active]="isDistrictSelected(getDistrictByName(districtAntwerpName)!)">
                      {{ "app.ui.nav.antwerp" | translate }}
                    </div>
                    <div class="mobile-menu-item" (click)="setGlobalSiteDistrictFilterToLimburg()"
                      data-bs-dismiss="offcanvas"
                      [class.active]="isDistrictSelected(getDistrictByName(districtLimburgName)!)">
                      {{ "app.ui.nav.limburg" | translate }}
                    </div>
                    <div class="mobile-menu-item" (click)="setGlobalSiteDistrictFilterToEastFlanders()"
                      data-bs-dismiss="offcanvas"
                      [class.active]="isDistrictSelected(getDistrictByName(districtEastFlandersName)!)">
                      {{ "app.ui.nav.east-flanders" | translate }}
                    </div>
                    <div class="mobile-menu-item" (click)="setGlobalSiteDistrictFilterToFlemishBrabant()"
                      data-bs-dismiss="offcanvas"
                      [class.active]="isDistrictSelected(getDistrictByName(districtFlemishBrabantName)!)">
                      {{ "app.ui.nav.flemish-brabant" | translate }}
                    </div>
                    <div class="mobile-menu-item" (click)="setGlobalSiteDistrictFilterToWestFlanders()"
                      data-bs-dismiss="offcanvas"
                      [class.active]="isDistrictSelected(getDistrictByName(districtWestFlandersName)!)">
                      {{ "app.ui.nav.west-flanders" | translate }}
                    </div>
                    <div class="mobile-menu-item" (click)="setGlobalSiteDistrictFilterToHainault()"
                      data-bs-dismiss="offcanvas"
                      [class.active]="isDistrictSelected(getDistrictByName(districtHainaultName)!)">
                      {{ "app.ui.nav.hainault" | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Right Section: Districts + User Menu -->
    <div class="nav-section-right">
      <!-- Desktop Districts Nav -->
      <div class="districts-nav d-none d-md-block">
        <ul class="navbar-nav">
          <li id="filter-nav" class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <span>{{ selectedGlobalFilter }}</span>
            </a>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" data-bs-dismiss="offcanvas" (click)="removeGlobalSiteDistrictFilter()"
                  [class.active]="isDistrictSelected(null)">{{
                  "app.ui.nav.all-districts" | translate }}</a></li>
              <hr class="text-white">
              <li><a class="dropdown-item" data-bs-dismiss="offcanvas" (click)="setGlobalSiteDistrictFilterToAntwerp()"
                  [class.active]="isDistrictSelected(getDistrictByName(districtAntwerpName)!)">{{
                  "app.ui.nav.antwerp" | translate }}</a></li>
              <li><a class="dropdown-item" data-bs-dismiss="offcanvas" (click)="setGlobalSiteDistrictFilterToLimburg()"
                  [class.active]="isDistrictSelected(getDistrictByName(districtLimburgName)!)">{{
                  "app.ui.nav.limburg" | translate }}</a></li>
              <li><a class="dropdown-item" data-bs-dismiss="offcanvas"
                  (click)="setGlobalSiteDistrictFilterToEastFlanders()"
                  [class.active]="isDistrictSelected(getDistrictByName(districtEastFlandersName)!)">{{
                  "app.ui.nav.east-flanders" | translate }}</a>
              </li>
              <li><a class="dropdown-item" data-bs-dismiss="offcanvas"
                  (click)="setGlobalSiteDistrictFilterToFlemishBrabant()"
                  [class.active]="isDistrictSelected(getDistrictByName(districtFlemishBrabantName)!)">{{
                  "app.ui.nav.flemish-brabant" | translate
                  }}</a></li>
              <li><a class="dropdown-item" data-bs-dismiss="offcanvas"
                  (click)="setGlobalSiteDistrictFilterToWestFlanders()"
                  [class.active]="isDistrictSelected(getDistrictByName(districtWestFlandersName)!)">{{
                  "app.ui.nav.west-flanders" | translate }}</a>
              </li>
              <hr class="text-white">
              <li><a class="dropdown-item" data-bs-dismiss="offcanvas" (click)="setGlobalSiteDistrictFilterToHainault()"
                  [class.active]="isDistrictSelected(getDistrictByName(districtHainaultName)!)">{{
                  "app.ui.nav.hainault" | translate }}</a></li>
            </ul>
          </li>
        </ul>
      </div>

      <!-- User Menu and Toggler Container -->
      <div class="user-menu-container">
        <!-- User Menu -->
        <div class="user-menu">
          <ul class="navbar-nav">
            <ng-container *ngIf="auth.isAuthenticated$ | async; else loggedOut">
              <li id="user-nav" class="nav-item dropdown no-arrow" *ngIf="auth.user$ | async as user">
                <a class="nav-link dropdown-toggle d-flex align-items-center" href="#" id="navbarDropdown" role="button"
                  data-bs-toggle="dropdown" aria-expanded="false">
                  <span class="me-2 d-none d-md-inline">{{ userFullName }}</span>
                  <img class="img-profile rounded-circle" title="profile-pic" [src]="profilePicturePath$ | async" />
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a class="dropdown-item" data-bs-dismiss="offcanvas" [routerLink]="['profile']">
                      {{ 'app.nav.profile' | translate }}
                    </a>
                  </li>
                  <li *ngIf="currentUser">
                    <a class="dropdown-item" data-bs-dismiss="offcanvas"
                      [routerLink]="['profile', 'settings', currentUser.id]">
                      {{ 'app.nav.settings' | translate }}
                    </a>
                  </li>
                  <li>
                    <hr class="dropdown-divider">
                  </li>
                  <li>
                    <a class="dropdown-item" data-bs-dismiss="offcanvas" routerLink="authenticated/gameform">
                      {{ 'app.nav.gameform' | translate }}
                    </a>
                  </li>
                  <li *ngIf="isAdmin">
                    <hr class="dropdown-divider">
                  </li>
                  <li *ngIf="isAdmin">
                    <a class="dropdown-item" data-bs-dismiss="offcanvas" [routerLink]="['admin']">
                      {{ 'app.nav.admin' | translate }}
                    </a>
                  </li>
                  <li>
                    <hr class="dropdown-divider">
                  </li>
                  <li>
                    <a class="dropdown-item" data-bs-dismiss="offcanvas" (click)="logout()">
                      {{ "application.navigation.logout" | translate }}
                    </a>
                  </li>
                </ul>
              </li>
            </ng-container>
            <ng-template #loggedOut>
              <li class="nav-item">
                <a class="nav-link login-link" (click)="auth.loginWithRedirect()">
                  {{ "app.ui.nav.login" | translate }}
                </a>
              </li>
            </ng-template>
          </ul>
        </div>

        <!-- Mobile Toggle Button -->
        <button class="navbar-toggler d-md-none" type="button" data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-expanded="false"
          aria-label="Toggle navigation">
          <fa-icon [icon]="faBars"></fa-icon>
        </button>
      </div>
    </div>
  </div>
</nav>
