import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { District } from '@app/core/models/district.model';
import { UserModel } from '@app/core/models/user.model';
import { SiteService } from '@app/core/services/site.service';
import { UserService } from '@app/core/services/user.service';
import { UserSettings } from '@app/features/authenticated/settings/models/user-settings.model';
import { AuthRepository } from '@app/store/auth/auth.repository';
import { MasterdataRepository } from '@app/store/masterdata/masterdata.repository';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from '@env/environment';
import { faBars, faFilter, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import {
  combineLatest,
  filter,
  from,
  lastValueFrom,
  map,
  Observable,
  of,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { ImageService } from '@app/core/services/image.service';

@Component({
  selector: 'app-nav',
  templateUrl: './app-nav.component.html',
  styleUrls: ['./app-nav.component.scss'],
})
export class AppNavComponent implements OnInit, OnDestroy {
  isAdmin: boolean = false;
  profilePicturePath$: Observable<string> = of();

  loaded: boolean = false;
  private destroy$ = new Subject<void>();

  currentUser!: UserModel;
  currentUserSettings: UserSettings | undefined = undefined;

  pinTopNav: boolean = false;
  districts: District[] = [];
  districtAntwerpName: string = 'Antwerpen';
  districtLimburgName = 'Limburg';
  districtEastFlandersName = 'Oost-Vlaanderen';
  districtFlemishBrabantName = 'Vlaams-Brabant';
  districtWestFlandersName = 'West-Vlaanderen';
  districtHainaultName = 'Henegouwen';
  districtLiegeName = 'Luik';
  isFilterNone: boolean = false;
  isFilterAntwerp: boolean = false;
  isFilterFlemishBrabant: boolean = false;
  isNonAdminRoute: boolean = false;
  faFacebook = faFacebook;
  faFilter = faFilter;
  faBars = faBars;
  faXMark = faXmark;
  isDevelopment = !environment.production;

  constructor(
    @Inject(DOCUMENT) public document: Document,
    public auth: AuthService,
    private authRepository: AuthRepository,
    private readonly masterDataRepository: MasterdataRepository,
    private readonly router: Router,
    private readonly msg: MessageService,
    private readonly userService: UserService,
    private readonly siteService: SiteService,
    private readonly imageService: ImageService,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.districts = this.masterDataRepository.getDistricts();
    this.isFilterNone = this.siteService.hasGlobalSiteDistrictFilterAll();
    this.isFilterAntwerp =
      this.siteService.hasGlobalSiteDistrictFilterAntwerp();
    this.isFilterFlemishBrabant =
      this.siteService.hasGlobalSiteDistrictFilterFlemishBrabant();

    this.profilePicturePath$ = this.auth.isAuthenticated$.pipe(
      takeUntil(this.destroy$),
      filter((isAuthenticated) => isAuthenticated),
      switchMap(() => this.auth.user$),
      switchMap((authUser) => from(this.fetchUser(btoa(authUser?.sub!)))),
      tap((user) => {
        this.currentUser = user!;
        this.isAdmin = this.authRepository.isAuthorized({
          name: 'module.view.administration',
          category: 'None',
          type: 'View',
        });
      }),
      switchMap((user) => {
        return combineLatest([
          this.userService.getUserSettings(user!.id),
          this.imageService.refresh$.pipe(
            startWith(null),
            switchMap(() => this.userService.getUserPictureById(user!.id))
          ),
        ]).pipe(
          tap(([settings, _]) => {
            this.currentUserSettings = settings;
            this.handleUserSettings(settings);
          }),
          map(([_, userPicture]) => {
            if (userPicture?.image) {
              return this.imageService.getSecureUrl(
                `${environment.settings.documentBase}${userPicture.image}`,
                true
              );
            } else {
              return this.imageService.getPlaceHolderImage(
                `${this.currentUser.firstName[0]}${this.currentUser.lastName[0]}`
              );
            }
          })
        );
      }),
      tap(() => (this.loaded = true))
    );

    // Subscribe to trigger the chain
    this.profilePicturePath$.subscribe();
  }

  private handleUserSettings(settings: UserSettings): void {
    if (
      settings.districtSiteFilterId !== undefined &&
      !this.siteService.hasGlobalSiteDistrictFilter()
    ) {
      this.siteService.setGlobalSiteDistrictFilter(
        this.districts.find(
          (x) => x.id === settings.districtSiteFilterId.toString().toLowerCase()
        )!,
        false
      );
    }

    if (
      settings.language !== undefined &&
      this.siteService.setCookieLanguage(settings.language?.languageCode)
    ) {
      this.translateService.use(settings.language?.languageCode!);
      location.reload();
    }
  }

  async fetchUser(authId: string) {
    try {
      return await lastValueFrom(this.userService.getUserByAuthId(authId));
    } catch (error) {
      if (error instanceof HttpErrorResponse && error.status === 404) {
        console.debug('User not found. Please check the ID and try again.');
        this.router.navigate(['/unauthorized'], {
          skipLocationChange: true,
          state: {
            forceLogout: true,
            error: this.translateService.instant('app.ui.nav.user-not-found'),
          },
        });
      }

      return null;
    }
  }

  public setGlobalSiteDistrictFilter(district: District | null): void {
    this.siteService.setGlobalSiteDistrictFilter(district, true);
  }

  getDistrictByName(districtName: string) {
    return this.districts.find((x) => x.name === districtName);
  }

  isDistrictSelected(district: District | null) {
    let currentId = this.siteService.getGlobalSiteDistrictFilter();

    if(currentId === null && district === null)
      return true;

    return district?.id === currentId;
  }

  closeOffcanvas() {}

  public removeGlobalSiteDistrictFilter(): void {
    this.siteService.setGlobalSiteDistrictFilter(null, true);
  }

  public setGlobalSiteDistrictFilterToAntwerp(): void {
    const antwerp = this.districts.find((x) =>
      x.name.includes(this.districtAntwerpName)
    );
    this.siteService.setGlobalSiteDistrictFilter(antwerp!, true);
  }

  public setGlobalSiteDistrictFilterToLimburg(): void {
    const limburg = this.districts.find((x) =>
      x.name.includes(this.districtLimburgName)
    );
    this.siteService.setGlobalSiteDistrictFilter(limburg!, true);
  }

  public setGlobalSiteDistrictFilterToEastFlanders(): void {
    const eastFlanders = this.districts.find((x) =>
      x.name.includes(this.districtEastFlandersName)
    );
    this.siteService.setGlobalSiteDistrictFilter(eastFlanders!, true);
  }

  public setGlobalSiteDistrictFilterToFlemishBrabant(): void {
    const brabant = this.districts.find((x) =>
      x.name.includes(this.districtFlemishBrabantName)
    );
    this.siteService.setGlobalSiteDistrictFilter(brabant!, true);
  }

  public setGlobalSiteDistrictFilterToWestFlanders(): void {
    const westFlanders = this.districts.find((x) =>
      x.name.includes(this.districtWestFlandersName)
    );
    this.siteService.setGlobalSiteDistrictFilter(westFlanders!, true);
  }
  public setGlobalSiteDistrictFilterToHainault(): void {
    const hainault = this.districts.find((x) =>
      x.name.includes(this.districtHainaultName)
    );
    this.siteService.setGlobalSiteDistrictFilter(hainault!, true);
  }
  public setGlobalSiteDistrictFilterToLiege(): void {
    const liege = this.districts.find((x) =>
      x.name.includes(this.districtLiegeName)
    );
    this.siteService.setGlobalSiteDistrictFilter(liege!, true);
  }

  get selectedGlobalFilter() {
    let district = this.districts.find(
      (x) => x.id === this.siteService.getGlobalSiteDistrictFilter()
    );

    if (district === undefined) {
      return this.translateService.instant('app.ui.nav.all-districts');
    }

    return district?.name;
  }

  get userFullName() {
    if (this.currentUser !== undefined) {
      return `${this.currentUser.firstName} ${this.currentUser.lastName}`;
    }

    return '';
  }

  logout() {
    this.auth
      .logout({ logoutParams: { returnTo: document.location.origin } })
      .subscribe(() => this.authRepository.clearStore());
  }

  @HostListener('window:scroll', []) onWindowScroll() {
    if (window.scrollY > 30) {
      this.pinTopNav = true;
    } else {
      this.pinTopNav = false;
    }
  }

  goToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
