import {
  Component,
  EventEmitter,
  Input,
  model,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ImageService } from '@app/core/services/image.service';
import { UserService } from '@app/core/services/user.service';
import { AuthRepository } from '@app/store/auth/auth.repository';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from '@env/environment';
import { faArrowLeft, faBars } from '@fortawesome/pro-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import {
  filter,
  map,
  Observable,
  of,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';

@Component({
  selector: 'app-admin-top-nav',
  templateUrl: './admin-top-nav.component.html',
  styleUrls: ['./admin-top-nav.component.scss'],
})
export class AdminTopNavComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  faBars = faBars;
  faArrow = faArrowLeft;
  isAdmin: boolean = false;

  profilePicture$: Observable<string> = of();
  showBreadCrumb: boolean = false;
  menuItems!: MenuItem[];
  home: MenuItem = { icon: 'pi pi-home', routerLink: '/admin' };

  static readonly ROUTE_DATA_BREADCRUMB = 'breadcrumb';
  static readonly ROUTE_DATA_BREADCRUMB_PARENT = 'parent';

  @Input() sideBarState: boolean = false;
  @Output() sideBarCollapse = new EventEmitter<boolean>();

  constructor(
    public auth: AuthService,
    private imageService: ImageService,
    private authRepository: AuthRepository,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.menuItems = this.createBreadcrumbs(this.activatedRoute.root);

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(
        (_) =>
          (this.menuItems = this.createBreadcrumbs(this.activatedRoute.root))
      );

    this.auth.isAuthenticated$.subscribe((authenticated) => {
      let actions = this.authRepository.getApplicationActions();

      if (authenticated) {
        this.isAdmin = actions.some(
          (x) =>
            x.name == 'module.view.administration' &&
            x.category == 'None' &&
            x.type == 'View'
        );

        this.profilePicture$ = this.auth.isAuthenticated$.pipe(
          takeUntil(this.destroy$),
          filter((authenticated) => authenticated),
          switchMap(() => this.auth.user$),
          switchMap((authUser) =>
            this.userService.getUserByAuthId(btoa(authUser?.sub!))
          ),
          switchMap((user) => {
            return this.imageService.refresh$.pipe(
              startWith(null),
              switchMap(() => this.userService.getUserPictureById(user.id)),
              map((imageResult) => {
                if (imageResult) {
                  return this.imageService.getSecureUrl(
                    `${environment.settings.documentBase}${imageResult.image}`,
                    true
                  );
                } else {
                  return this.imageService.getPlaceHolderImage(
                    `${user.firstName[0]}${user.lastName[0]}`
                  );
                }
              })
            );
          })
        );
      }
    });
  }

  private createBreadcrumbs(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: MenuItem[] = []
  ): MenuItem[] {
    this.showBreadCrumb = false;
    let currentRoute = route.children.map((r) => {
      while (r.firstChild) {
        r = r.firstChild;
      }

      return r;
    })[0];

    if (
      currentRoute.snapshot?.data !== null &&
      currentRoute.snapshot?.data !== undefined
    ) {
      let parentRoutes =
        currentRoute.snapshot.data[
          AdminTopNavComponent.ROUTE_DATA_BREADCRUMB_PARENT
        ];
      let label =
        currentRoute.snapshot.data[AdminTopNavComponent.ROUTE_DATA_BREADCRUMB];

      if (label != null && label != undefined) {
        if (parentRoutes != null && parentRoutes !== undefined) {
          parentRoutes.map((parentRoute: { url: string; label: string }) => {
            breadcrumbs.push(this.findParentBreadCrumbs(parentRoute));
          });
        }

        breadcrumbs.push({ label: this.translate.instant(label) });
      }

      if (breadcrumbs.length > 0) {
        this.showBreadCrumb = true;
      }

      return breadcrumbs;
    }

    return [];
  }

  private findParentBreadCrumbs(crumbfig: {
    url: string;
    label: string;
  }): MenuItem {
    if (crumbfig.url === '') {
      crumbfig.url = this.router.url.substring(
        0,
        this.router.url.lastIndexOf('/')
      );
    }

    return {
      label: this.translate.instant(crumbfig.label),
      url: crumbfig.url,
      target: '_self',
    };
  }

  logout() {
    this.auth
      .logout({ logoutParams: { returnTo: document.location.origin } })
      .subscribe(() => this.authRepository.clearStore());
  }

  onToggleSideNav() {
    this.sideBarState = !this.sideBarState;
    this.sideBarCollapse.emit(this.sideBarState);
  }
}
